// 获取当前年月日
export const getNowDayFn = () => {
  const time = new Date()
  const year = time.getFullYear()
  const month = time.getMonth() + 1
  const month1 = month < 10 ? '0' + month : month
  const date = time.getDate()
  const date1 = date < 10 ? '0' + date : date
  return `${year}-${month1}-${date1}`
}

// 获取当前时间
export const getNowTime = () => {
  const time = new Date()
  const hours = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
  const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
  const seconds = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
  return `${hours}:${minutes}:${seconds}`
}

export const getWeekDate = () => {
  var now = new Date()
  var day = now.getDay()
  var weeks = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六')
  var week = weeks[day]
  return week
}

export const parseTime = (time, fm) => { // 解析时间  time: 时间戳或者实践对象 fm: 格式 默认是{y}-{m}-{d} {h}:{i}:{s}
  if (arguments.length === 0) {
    return null
  }
  const format = fm || '{yyyy}-{mm}-{dd} {hh}:{ii}:{ss}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// apk
export const apk = (val) => {
  let www = ''
  switch (val) {
    case 'development' :
      www = 'http://file.xuruidea.com/test/yxzhsl_test.apk'
      break
    case 'test': // 测试环境
      www = 'http://file.xuruidea.com/test/yxzhsl_test.apk'
      break
    case 'uat': // 生产环境
      www = 'http://file.xuruidea.com/uat/yxzhsl_uat.apk'
      break
    default :
      www = 'http://file.xuruidea.com/uat/yxzhsl_uat.apk'
      break
  }
  return www
}
